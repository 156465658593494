// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Button from "../../../../../styleguide/components/Button/Button.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocationsIndexBookACall from "./components/LocationsIndexBookACall.res.js";
import * as LocationsIndexBookACallSectionCss from "./LocationsIndexBookACallSectionCss.res.js";

function LocationsIndexBookACallSection(props) {
  var match = React.useState(function () {
        return false;
      });
  var setModalOpen = match[1];
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("h2", {
                      children: "Looking for the Best Offer? Our Experts are Always Ready to Help!",
                      className: LocationsIndexBookACallSectionCss.heading
                    }),
                JsxRuntime.jsx(Button.make, {
                      size: "XS",
                      color: "Teal",
                      onClick: (function (param) {
                          setModalOpen(function (param) {
                                return true;
                              });
                        }),
                      children: "Book a Call"
                    }),
                match[0] ? JsxRuntime.jsx(LocationsIndexBookACall.make, {
                        onClose: (function () {
                            setModalOpen(function (param) {
                                  return false;
                                });
                          })
                      }) : null
              ],
              className: LocationsIndexBookACallSectionCss.container
            });
}

var Css;

var make = LocationsIndexBookACallSection;

export {
  Css ,
  make ,
}
/* react Not a pure module */
