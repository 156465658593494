// Generated by ReScript, PLEASE EDIT WITH CARE

import * as LeadModal from "../../../../../../styleguide/modals/LeadModal/LeadModal.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function LocationsIndexBookACall(props) {
  return JsxRuntime.jsx(LeadModal.make, {
              title: "Get Free Colocation Advice",
              subtitle: "Need help finding Colocation? Our experts can guide you through the process of submitting your quote request directly to multiple providers at once.",
              buttonTitle: "Submit",
              close: props.onClose,
              leadType: "Colocation"
            });
}

var make = LocationsIndexBookACall;

export {
  make ,
}
/* LeadModal Not a pure module */
